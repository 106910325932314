import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Preloader from "./layout/Preloader";

const Home = lazy(() => import("./ui/Home"));
const Career = lazy(() => import("./ui/Career"));
//don
const About = lazy(() => import("./ui/About"));
const Event = lazy(() => import("./components/Event/Event"));

const Service = lazy(() => import("./ui/Service"));
const NoPage = lazy(() => import("./layout/NoPage"));
const ContactUs = lazy(() => import("./ui/ContectUs/ContectUs"));
//done
function App() {
  return (
    <div className="appmy">
      <Router>
        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="members" element={<About />} />
            <Route path="aboutus" element={<Career />} />
            <Route path="magazine" element={<Service />} />
            <Route path="contactus" element={<ContactUs />} />
            <Route path="Event" element={<Event />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
